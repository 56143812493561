import { createContext, useState } from "react";
import {
  createNewProduct,
  editDetailsType,
  requestType,
} from "../Utilities/types";
import { requestHandler } from "../HelperFunctions/requestHandler";
import { v4 } from "uuid";
import useError from "../Hooks/useError";

type TreasuryContextValuesTypes = {
  uploadProducts: (type: string, uploadedData: createNewProduct[]) => void;
  requestState: requestType;
  getProducts: (
    pageNumber: number,
    startDate?: string,
    endDate?: string,
    additionalParams?: { [key: string]: string | number }
  ) => void;
  productsState: requestType;
  getProductyId: (id: string) => void;
  productState: requestType;
  editProduct: (id: string, data: editDetailsType) => void;
  getPurchases: (
    pageNumber: number,
    startDate?: string,
    endDate?: string,
    additionalParams?: { [key: string]: string | number }
  ) => void;
  purchasesState: requestType;
  revokePurchase: (id: string, reason: string) => void;
  approvePurchase: (id: string) => void;
  sendContract: (id: string) => void;
  viewContract: (id: string) => void;
  viewContractInfo: requestType;
};

type TreasuryContextProviderTypes = {
  children: React.ReactNode;
};

export const TreasuryContext = createContext({} as TreasuryContextValuesTypes);

const TreasuryContextProvider = ({
  children,
}: TreasuryContextProviderTypes) => {
  // COntext
  const { errorFlowFunction } = useError();

  // States
  const [requestState, setRequestState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [productsState, setProductsState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [productState, setProductState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [purchasesState, setPurchasesState] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });
  const [viewContractInfo, setViewContractInfo] = useState<requestType>({
    isLoading: false,
    data: null,
    error: null,
  });

  //   Requests
  const uploadProducts = (type: string, uploadedData: createNewProduct[]) => {
    requestHandler({
      method: "POST",
      data: {
        products: uploadedData,
        requestId: v4().slice(0, 25),
      },
      url: `/api/v1/Product/upload-product/${type}`,
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "upload-products",
      requestCleanup: true,
    });
  };

  const getProducts = (
    pageNumber = 1,
    startDate?: string,
    endDate?: string,
    additionalParams: { [key: string]: string | number } = {}
  ) => {
    const basePath = `/api/v1/Product/get-products`;

    const queryParams = new URLSearchParams();

    queryParams.append("PageNumber", pageNumber.toString());

    if (startDate && endDate) {
      queryParams.append("DateFilter.StartDate", startDate);
      queryParams.append("DateFilter.EndDate", endDate);
    }

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        queryParams.append(key, value?.toString());
      }
    });

    const url = `${basePath}?${queryParams.toString()}`;

    requestHandler({
      method: "GET",
      url,
      state: productsState,
      setState: setProductsState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const getProductyId = (id: string) => {
    requestHandler({
      method: "GET",
      url: `/api/v1/Product/get-product/${id}`,
      state: productState,
      setState: setProductState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const editProduct = (id: string, data: editDetailsType) => {
    requestHandler({
      method: "POST",
      data: { ...data, requestId: v4().substring(0, 20) },
      url: `/api/v1/Product/update-product/${id}`,
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "edit-product",
      requestCleanup: true,
    });
  };

  const getPurchases = (
    pageNumber = 1,
    startDate?: string,
    endDate?: string,
    additionalParams: { [key: string]: string | number } = {}
  ) => {
    const basePath = `/api/v1/Subscription/get-subscriptions`;

    const queryParams = new URLSearchParams();

    queryParams.append("PageNumber", pageNumber.toString());

    if (startDate && endDate) {
      queryParams.append("DateRange.StartDate", startDate);
      queryParams.append("DateRange.EndDate", endDate);
    }

    Object.entries(additionalParams).forEach(([key, value]) => {
      if (value !== undefined && value !== null) {
        queryParams.append(key, value?.toString());
      }
    });

    const url = `${basePath}?${queryParams.toString()}`;

    requestHandler({
      method: "GET",
      url,
      state: purchasesState,
      setState: setPurchasesState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
    });
  };

  const revokePurchase = (id: string, reason: string) => {
    requestHandler({
      method: "POST",
      url: `/api/v1/Treasury/revoke`,
      data: {
        id,
        requestId: v4().slice(0, 25),
        rejectionReason: reason,
      },
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "revoke-purchase",
      requestCleanup: true,
    });
  };

  const approvePurchase = (id: string) => {
    requestHandler({
      method: "POST",
      url: `/api/v1/Treasury/approve`,
      data: {
        id,
        requestId: v4().slice(0, 25),
      },
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "approve-purchase",
      requestCleanup: true,
    });
  };

  const viewContract = (id: string) => {
    requestHandler({
      method: "POST",
      data: {
        requestId: v4().slice(0, 25),
        id,
      },
      url: `/api/v1/Treasury/view-contract`,
      state: viewContractInfo,
      setState: setViewContractInfo,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "view-contract",
    });
  };

  const sendContract = (id: string) => {
    requestHandler({
      method: "POST",
      data: {
        requestId: v4().slice(0, 25),
        id,
      },
      url: `/api/v1/Treasury/send-contract`,
      state: requestState,
      setState: setRequestState,
      errorFunction(err: any) {
        errorFlowFunction(err);
      },
      id: "send-contract",
      requestCleanup: true,
    });
  };

  return (
    <TreasuryContext.Provider
      value={{
        uploadProducts,
        requestState,
        getProducts,
        productsState,
        getProductyId,
        productState,
        editProduct,
        getPurchases,
        purchasesState,
        revokePurchase,
        approvePurchase,
        viewContract,
        sendContract,
        viewContractInfo,
      }}
    >
      {children}
    </TreasuryContext.Provider>
  );
};

export default TreasuryContextProvider;
