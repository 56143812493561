import { generateHeader } from "../Utilities/generateHeaders";
import { config } from "../Config";
import axios from "axios";
import {
  SESSION_STORAGE_AUTH_KEY,
  SESSION_STORAGE_AUTH_REFRESH_KEY,
} from "../Utilities/constants";
import { sessionStorageHandler } from "../HelperFunctions/sessionStorageHandler";
import { v4 } from "uuid";

const axiosInstance = axios.create({
  baseURL: config.base_url,
  headers: {
    "X-Frame-Options": "DENY",
    "X-Content-Type-Options": "nosniff",
    "Cross-Origin-Opener-Policy": "same-origin",
    "Content-Security-Policy": "frame-ancestors 'self' X-Frame-Options: DENY",
    "Cache-Control": "no-store, no-cache, must-revalidate, proxy-revalidate",
    Pragma: "no-cache",
    Expires: "0",
  },
});

let isRefreshing = false;
let refreshSubscribers: any[] = [];

function subscribeTokenRefresh(cb: any) {
  refreshSubscribers.push(cb);
}

function onRefreshed(newToken: any) {
  refreshSubscribers.forEach((callback) => callback(newToken));
  refreshSubscribers = [];
}

const getRefreshToken = async () => {
  const token = sessionStorageHandler(
    "get",
    SESSION_STORAGE_AUTH_KEY as string,
    undefined
  );
  const refreshToken = sessionStorageHandler(
    "get",
    SESSION_STORAGE_AUTH_REFRESH_KEY as string,
    undefined
  );

  try {
    const response = await axiosInstance.post(
      "api/v1/Authentication/refresh-token",
      {
        requestId: v4().slice(0, 25),
        token: token,
        refreshToken: refreshToken,
      },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    throw error;
  }
};

axiosInstance.interceptors.response.use(
  (response) => response,
  async function (error) {
    const { config, response } = error;
    if (!config || response.status !== 401) {
      return Promise.reject(error);
    }

    if (config._retry) {
      return Promise.reject(error);
    }

    config._retry = true;

    if (isRefreshing) {
      return new Promise((resolve, reject) => {
        subscribeTokenRefresh((newToken: any) => {
          config.headers.Authorization = `Bearer ${newToken}`;
          resolve(axiosInstance(config));
        });
      });
    }

    isRefreshing = true;
    try {
      const refreshResponse = await getRefreshToken();
      const newAccessToken = refreshResponse?.data?.token;
      const newRefreshToken = refreshResponse?.data?.refreshToken;

      sessionStorageHandler(
        "set",
        SESSION_STORAGE_AUTH_KEY as string,
        newAccessToken
      );
      sessionStorageHandler(
        "set",
        SESSION_STORAGE_AUTH_REFRESH_KEY as string,
        newRefreshToken
      );

      axiosInstance.defaults.headers.common[
        "Authorization"
      ] = `Bearer ${newAccessToken}`;

      onRefreshed(newAccessToken);
      isRefreshing = false;

      config.headers.Authorization = `Bearer ${newAccessToken}`;
      return axiosInstance(config);
    } catch (refreshError) {
      isRefreshing = false;
      refreshSubscribers = [];
      return Promise.reject(refreshError);
    }
  }
);

axiosInstance.interceptors.request.use(
  (axiosConfig) => {
    if (!navigator.onLine) {
      throw new Error("Please check your Internet Connection");
    }

    const headers = generateHeader();
    axiosConfig.headers["x-token"] = headers["x-token"];
    axiosConfig.headers["Ocp-Apim-Subscription-Key"] =
      headers["Ocp-Apim-Subscription-Key"];
    axiosConfig.headers["Ocp-Apim-Trace"] = true;
    axiosConfig.headers.UTCTimestamp = headers.UTCTimestamp;
    axiosConfig.headers.Client_ID = headers.Client_ID;

    return axiosConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data.responseCode === "00" || response?.status === 200) {
      return response;
    } else {
      throw new Error(response?.data?.error?.message);
    }

    // return response;
  },
  async (err: any) => {
    return Promise.reject(err);
  }
);

export default axiosInstance;
