export const sessionStorageHandler = (
  method: "set" | "get" | "delete",
  key: string,
  value?: string
) => {
  if (method === "get") {
    return sessionStorage.getItem(key);
  }
  if (method === "set" && value) {
    sessionStorage.setItem(key, value);
  }
  if (method === "delete") {
    sessionStorage.removeItem(key);
  }
};
