import { Navigate, Route, Routes } from "react-router-dom";
import { routes } from "./Utilities/routes";
import { routePaths } from "./Utilities/routePaths";
import React from "react";
import RequireAuth from "./Components/RequireAuth/RequireAuth";

function App() {
  return (
    <Routes>
      <Route
        path={routePaths.BASE_URL}
        element={<Navigate to={routePaths.TRESURY_PRODUCTS} />}
      />

      {routes.map((route, i) => {
        if (route?.properties?.includes("isProtected")) {
          return (
            <React.Fragment key={i}>
              <Route element={<RequireAuth />}>
                <Route path={route.route} element={route.element} />
              </Route>
              ;
            </React.Fragment>
          );
        } else {
          return (
            <React.Fragment key={i}>
              <Route path={route.route} element={route.element} />
            </React.Fragment>
          );
        }
      })}
    </Routes>
  );
}

export default App;
