import { sessionStorageHandler } from "../../HelperFunctions/sessionStorageHandler";
import { SESSION_STORAGE_AUTH_KEY } from "../../Utilities/constants";
import { Navigate, Outlet, useLocation } from "react-router-dom";
import { routePaths } from "../../Utilities/routePaths";
import { useContext } from "react";
import { AuthContext } from "../../Context/AuthContext";
import Loader from "../Loader/Loader";

const RequireAuth = () => {
  // Local
  const accessToken = sessionStorageHandler(
    "get",
    SESSION_STORAGE_AUTH_KEY as string
  );

  // COntext
  const { user } = useContext(AuthContext);

  // Router
  const location = useLocation();

  return (
    <>
      {accessToken && user?.isLoading ? (
        <Loader />
      ) : accessToken && !user?.isLoading ? (
        <Outlet />
      ) : (
        <Navigate
          to={routePaths.SIGN_IN}
          replace={true}
          state={location.pathname}
        />
      )}
    </>
  );
};

export default RequireAuth;
