import React, { lazy, Suspense } from "react";
import { routePaths } from "./routePaths";
import Loader from "../Components/Loader/Loader";

const SignIn = lazy(() => import("../Containers/SignIn/SignIn"));
const TresuryProducts = lazy(
  () => import("../Containers/TresuryProducts/TresuryProducts")
);
const Transactions = lazy(
  () => import("../Containers/Transactions/Transactions")
);

type routesType = {
  route: string;
  element: React.ReactNode;
  properties: any[] | null;
  name: string;
}[];

export const routes: routesType = [
  {
    route: routePaths.SIGN_IN,
    element: (
      <Suspense fallback={<Loader />}>
        <SignIn />
      </Suspense>
    ),
    properties: [],
    name: "Sign In",
  },

  {
    route: routePaths.TRESURY_PRODUCTS,
    element: (
      <Suspense fallback={<Loader />}>
        <TresuryProducts />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Treasury Proucts",
  },

  {
    route: routePaths.TRANSACTIONS,
    element: (
      <Suspense fallback={<Loader />}>
        <Transactions />
      </Suspense>
    ),
    properties: ["isProtected", "isSideNavRoute"],
    name: "Transactions",
  },
];
